import React from 'react'
import Link from 'gatsby-link'
import headerImage from '../images/ecommerce/magento-demos@2x.png';
import logoWordPress from '../images/web/wordpress-white.png';

import './css/web.scss'
import Layout from "../components/layout";
import SEO from "../components/seo";

const WebPage = () => (
    <Layout>
        <div className="main" role="main">
            <SEO title="Desenvolvimento de Sites" description="" keywords={[``]} />
            <div className="main-img ui-hero hero-lg hero-center hero-svg-layer-1 bg-indigo bg-web ui-tilt hero-svg-layer svg-layer-2">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12" data-vertical_center="true" data-vertical_offset="16">
                            <h1 className="heading animate" data-show="fade-in-up-big" data-delay="100">
                                Sua  <span className="text-cyan-dark">Empresa</span> preparada para o <span className="text-cyan-dark">Futuro</span>
                            </h1>
                        </div>
                        <div className="col-sm-12 animate" data-show="fade-in-up" data-delay="400">
                            <img src={headerImage} alt="Magento 2 System Code"
                                 data-uhd data-max_width="1000" className="responsive-on-lg" />
                        </div>
                    </div>
                </div>
            </div>
            <div id="features" className="section">
                <div className="container ui-icon-blocks ui-blocks-h icons-lg">
                    <div className="row">
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle icon-rocket ui-gradient-blue"></div>
                            <h4>Desenvolvimento</h4>
                            <p>
                                Desenvolvemos seu site do zero, seja ele estático ou dinâmico!
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle icon-earphones-alt ui-gradient-peach"></div>
                            <h4>Suporte / Manutenção</h4>
                            <p>
                                Equipe de suporte especializada 24/7<br/>para dúvidas ou problemas técnicos.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle icon-trophy ui-gradient-green"></div>
                            <h4>Consultoria</h4>
                            <p>
                                Consultoria especializada para criação do seu site.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section ui-gradient-sc ui-action-section ui-hero hero-svg-layer-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-7 text-block" data-vertical_center="true">
                            <div className="section-heading">
                                <h2 className="heading text-white">
                                    É Importante Ter Um Site?
                                </h2>
                                <p className="text-white justify-font-center">
                                    Todo negócio precisa de um site, seja para passar maior credibilidade
                                  ou para divulgar produtos e serviços. Além do mais, um site expande a sua
                                  área de atuação e é uma maneira de se relacionar com novos clientes.
                                </p>

                                <p className="text-white justify-font-center">
                                     Para sites dinâmicos, na maioria dos casos, a <strong>System Code </strong>
                                    recomenda o uso da plataforma Wordpress, uma plataforma fácil para o gerenciamento
                                    de conteúdos e que por ser amplamente utilizada, possui excelentes ferramentas para
                                    performance, rankeamento em sites de buscas, gerenciamento de conteúdos e muito mais.
                                </p>

                            </div>
                        </div>
                        <div className="logo-wordpress col-md-4 col-sm-5 img-block animate" data-show="fade-in-left">
                            <img src={logoWordPress} alt="WordPress"
                                 data-uhd className="responsive-on-sm" data-max_width="450"/>
                        </div>

                    </div>

                </div>
            </div>

            <div className="section bg-light">
                <div className="container ui-icon-blocks ui-blocks-h icons-md">
                    <div className="section-heading center">
                        <h2 className="heading text-indigo">
                            O Que Oferecemos
                        </h2>
                        <p>
                            Conheça os principais recursos que oferecemos para nossos clientes na plaforma <strong>Wordpress</strong>
                        </p>
                    </div>
                    <div className="row animate" data-show="fade-in" >
                        <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
                            <div className="icon icon-wrench text-purple"></div>
                            <h5>Plugins</h5>
                            <p>
                                Desenvolvimento e/ou instalação de plugins customizados.
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
                            <div className="icon icon-puzzle text-lime"></div>
                            <h5>Temas Personalizados</h5>
                            <p>
                                Criação e customização de temas com a identidade do seu negócio.
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
                            <div className="icon icon-paper-plane text-pink"></div>
                            <h5>Integrações</h5>
                            <p>
                                Seu site integrado com as mídias sociais, aplicativos e sistemas.
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
                            <div className="icon icon-speedometer text-blue"></div>
                            <h5>Infraestrutura</h5>
                            <p>
                                Rápida e eficiente para fornecer melhor experência para o seu cliente.
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
                            <div className="icon icon-lock text-orange"></div>
                            <h5>Segurança</h5>
                            <p>
                                Mantemos seu site seguro com atualizações e HTTPS.
                            </p>
                        </div>

                        <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
                            <div className="icon icon-people text-dark"></div>
                            <h5>Treinamento</h5>
                            <p>
                                Treinamos sua equipe para aproveitar todos os recursos da plataforma.
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
                            <div className="icon icon-layers text-cyan"></div>
                            <h5>SEO/SEM</h5>
                            <p>
                                Seu site otimizado em plataformas de pesquisa e em links patrocinados.
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
                            <div className="icon icon-screen-smartphone text-yellow"></div>
                            <h5>Responsivo</h5>
                            <p>
                                Seu site se adaptado a todos os dispositivos.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" data-vertical_center="true">
                            <div className="section-heading">
                                <h2 className="heading text-indigo text-center">
                                    Saiba Mais
                                </h2>

                                <p className="paragraph faq">
                                    Confirma dúvidas mais comuns na hora de ter seu site
                                </p>

                                <div className="ui-accordion-panel ui-indigo">
                                    <div className="ui-card shadow-sm ui-accordion">
                                        <h6 className="toggle" data-toggle="accordion-one">1. Para quem recomendam?</h6>
                                        <div className="body in" data-accord="accordion-one">
                                            <p>Nós da <strong>System Code</strong> recomendamos para as empresas e
                                                profissionais liberais que querem ampliar
                                                a divulgação de seus produtos e serviços, aumentar sua área de atuação no mercado e
                                                ficar mais próximo do seu cliente.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="ui-card shadow-sm ui-accordion">
                                        <h6 className="toggle" data-toggle="accordion-two">2. Por onde começar?</h6>
                                        <div className="body" data-accord="accordion-two">
                                            <p>O primeiro passo é pesquisar sites similares ao que deseja,
                                                definir o contéudo das páginas e as imagens.
                                                Com isso em mãos, entre em contato com conosco, possuimos uma
                                                equipe qualificada para auxiliar no processo de criação de seu site.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="ui-card shadow-sm ui-accordion">
                                        <h6 className="toggle" data-toggle="accordion-three">3. Já tenho um site!</h6>
                                        <div className="body" data-accord="accordion-three">
                                            <p>Caso pretenda utilizar o <strong>Wordpress</strong> ou esteja precisando de uma empresa
                                                capacitada para
                                                auxiliar no processo ou melhorias de seu site, conte conosco.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="ui-card shadow-sm ui-accordion">
                                        <h6 className="toggle" data-toggle="accordion-four">4. Ainda com dúvidas?</h6>
                                        <div className="body" data-accord="accordion-four">
                                            <p>Ficou interessado em ter seu site mas continua com alguma dúvida? Entre em contato
                                                com a <strong>System Code</strong> que iremos te ajudar!
                                            </p>
                                            <Link to="contato" className="btn-link btn-arrow">Entre em Contato</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default WebPage
